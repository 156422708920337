import React from "react"
import Layout from "../components/layout"

export default function noPage() {
  return (
  <Layout>
    <h1>404</h1>
  </Layout>
  );
}
    
  